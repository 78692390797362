import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetchData } from '../lib/fetcher';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  numeric: any;
  timestamptz: any;
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** columns and relationships of "Category" */
export type Category = {
  __typename?: 'Category';
  /** An array relationship */
  Request_Categories: Array<Request_Category>;
  /** An aggregated array relationship */
  Request_Categories_aggregate: Request_Category_Aggregate;
  id: Scalars['Int'];
  name: Scalars['String'];
};


/** columns and relationships of "Category" */
export type CategoryRequest_CategoriesArgs = {
  distinct_on?: Maybe<Array<Request_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Request_Category_Order_By>>;
  where?: Maybe<Request_Category_Bool_Exp>;
};


/** columns and relationships of "Category" */
export type CategoryRequest_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Request_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Request_Category_Order_By>>;
  where?: Maybe<Request_Category_Bool_Exp>;
};

/** aggregated selection of "Category" */
export type Category_Aggregate = {
  __typename?: 'Category_aggregate';
  aggregate?: Maybe<Category_Aggregate_Fields>;
  nodes: Array<Category>;
};

/** aggregate fields of "Category" */
export type Category_Aggregate_Fields = {
  __typename?: 'Category_aggregate_fields';
  avg?: Maybe<Category_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Category_Max_Fields>;
  min?: Maybe<Category_Min_Fields>;
  stddev?: Maybe<Category_Stddev_Fields>;
  stddev_pop?: Maybe<Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Category_Stddev_Samp_Fields>;
  sum?: Maybe<Category_Sum_Fields>;
  var_pop?: Maybe<Category_Var_Pop_Fields>;
  var_samp?: Maybe<Category_Var_Samp_Fields>;
  variance?: Maybe<Category_Variance_Fields>;
};


/** aggregate fields of "Category" */
export type Category_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Category_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Category" */
export type Category_Aggregate_Order_By = {
  avg?: Maybe<Category_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Category_Max_Order_By>;
  min?: Maybe<Category_Min_Order_By>;
  stddev?: Maybe<Category_Stddev_Order_By>;
  stddev_pop?: Maybe<Category_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Category_Stddev_Samp_Order_By>;
  sum?: Maybe<Category_Sum_Order_By>;
  var_pop?: Maybe<Category_Var_Pop_Order_By>;
  var_samp?: Maybe<Category_Var_Samp_Order_By>;
  variance?: Maybe<Category_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Category" */
export type Category_Arr_Rel_Insert_Input = {
  data: Array<Category_Insert_Input>;
  on_conflict?: Maybe<Category_On_Conflict>;
};

/** aggregate avg on columns */
export type Category_Avg_Fields = {
  __typename?: 'Category_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Category" */
export type Category_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Category". All fields are combined with a logical 'AND'. */
export type Category_Bool_Exp = {
  Request_Categories?: Maybe<Request_Category_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Category_Bool_Exp>>>;
  _not?: Maybe<Category_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Category_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Category" */
export enum Category_Constraint {
  /** unique or primary key constraint */
  CategoryIdKey = 'Category_id_key',
  /** unique or primary key constraint */
  CategoryPkey = 'Category_pkey'
}

/** input type for incrementing integer column in table "Category" */
export type Category_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Category" */
export type Category_Insert_Input = {
  Request_Categories?: Maybe<Request_Category_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Category_Max_Fields = {
  __typename?: 'Category_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Category" */
export type Category_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Category_Min_Fields = {
  __typename?: 'Category_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Category" */
export type Category_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "Category" */
export type Category_Mutation_Response = {
  __typename?: 'Category_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Category>;
};

/** input type for inserting object relation for remote table "Category" */
export type Category_Obj_Rel_Insert_Input = {
  data: Category_Insert_Input;
  on_conflict?: Maybe<Category_On_Conflict>;
};

/** on conflict condition type for table "Category" */
export type Category_On_Conflict = {
  constraint: Category_Constraint;
  update_columns: Array<Category_Update_Column>;
  where?: Maybe<Category_Bool_Exp>;
};

/** ordering options when selecting data from "Category" */
export type Category_Order_By = {
  Request_Categories_aggregate?: Maybe<Request_Category_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "Category" */
export type Category_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Category" */
export enum Category_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "Category" */
export type Category_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Category_Stddev_Fields = {
  __typename?: 'Category_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Category" */
export type Category_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Category_Stddev_Pop_Fields = {
  __typename?: 'Category_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Category" */
export type Category_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Category_Stddev_Samp_Fields = {
  __typename?: 'Category_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Category" */
export type Category_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Category_Sum_Fields = {
  __typename?: 'Category_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Category" */
export type Category_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "Category" */
export enum Category_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Category_Var_Pop_Fields = {
  __typename?: 'Category_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Category" */
export type Category_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Category_Var_Samp_Fields = {
  __typename?: 'Category_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Category" */
export type Category_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Category_Variance_Fields = {
  __typename?: 'Category_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Category" */
export type Category_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** columns and relationships of "QuestionPairs" */
export type QuestionPairs = {
  __typename?: 'QuestionPairs';
  Id: Scalars['Int'];
  Q1?: Maybe<Scalars['String']>;
  Q2?: Maybe<Scalars['String']>;
  Similarity?: Maybe<Scalars['Boolean']>;
  Test?: Maybe<Scalars['Boolean']>;
};

/** aggregated selection of "QuestionPairs" */
export type QuestionPairs_Aggregate = {
  __typename?: 'QuestionPairs_aggregate';
  aggregate?: Maybe<QuestionPairs_Aggregate_Fields>;
  nodes: Array<QuestionPairs>;
};

/** aggregate fields of "QuestionPairs" */
export type QuestionPairs_Aggregate_Fields = {
  __typename?: 'QuestionPairs_aggregate_fields';
  avg?: Maybe<QuestionPairs_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<QuestionPairs_Max_Fields>;
  min?: Maybe<QuestionPairs_Min_Fields>;
  stddev?: Maybe<QuestionPairs_Stddev_Fields>;
  stddev_pop?: Maybe<QuestionPairs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<QuestionPairs_Stddev_Samp_Fields>;
  sum?: Maybe<QuestionPairs_Sum_Fields>;
  var_pop?: Maybe<QuestionPairs_Var_Pop_Fields>;
  var_samp?: Maybe<QuestionPairs_Var_Samp_Fields>;
  variance?: Maybe<QuestionPairs_Variance_Fields>;
};


/** aggregate fields of "QuestionPairs" */
export type QuestionPairs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<QuestionPairs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "QuestionPairs" */
export type QuestionPairs_Aggregate_Order_By = {
  avg?: Maybe<QuestionPairs_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<QuestionPairs_Max_Order_By>;
  min?: Maybe<QuestionPairs_Min_Order_By>;
  stddev?: Maybe<QuestionPairs_Stddev_Order_By>;
  stddev_pop?: Maybe<QuestionPairs_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<QuestionPairs_Stddev_Samp_Order_By>;
  sum?: Maybe<QuestionPairs_Sum_Order_By>;
  var_pop?: Maybe<QuestionPairs_Var_Pop_Order_By>;
  var_samp?: Maybe<QuestionPairs_Var_Samp_Order_By>;
  variance?: Maybe<QuestionPairs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "QuestionPairs" */
export type QuestionPairs_Arr_Rel_Insert_Input = {
  data: Array<QuestionPairs_Insert_Input>;
  on_conflict?: Maybe<QuestionPairs_On_Conflict>;
};

/** aggregate avg on columns */
export type QuestionPairs_Avg_Fields = {
  __typename?: 'QuestionPairs_avg_fields';
  Id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "QuestionPairs" */
export type QuestionPairs_Avg_Order_By = {
  Id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "QuestionPairs". All fields are combined with a logical 'AND'. */
export type QuestionPairs_Bool_Exp = {
  Id?: Maybe<Int_Comparison_Exp>;
  Q1?: Maybe<String_Comparison_Exp>;
  Q2?: Maybe<String_Comparison_Exp>;
  Similarity?: Maybe<Boolean_Comparison_Exp>;
  Test?: Maybe<Boolean_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<QuestionPairs_Bool_Exp>>>;
  _not?: Maybe<QuestionPairs_Bool_Exp>;
  _or?: Maybe<Array<Maybe<QuestionPairs_Bool_Exp>>>;
};

/** unique or primary key constraints on table "QuestionPairs" */
export enum QuestionPairs_Constraint {
  /** unique or primary key constraint */
  QuestionPairsPkey = 'QuestionPairs_pkey'
}

/** input type for incrementing integer column in table "QuestionPairs" */
export type QuestionPairs_Inc_Input = {
  Id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "QuestionPairs" */
export type QuestionPairs_Insert_Input = {
  Id?: Maybe<Scalars['Int']>;
  Q1?: Maybe<Scalars['String']>;
  Q2?: Maybe<Scalars['String']>;
  Similarity?: Maybe<Scalars['Boolean']>;
  Test?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type QuestionPairs_Max_Fields = {
  __typename?: 'QuestionPairs_max_fields';
  Id?: Maybe<Scalars['Int']>;
  Q1?: Maybe<Scalars['String']>;
  Q2?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "QuestionPairs" */
export type QuestionPairs_Max_Order_By = {
  Id?: Maybe<Order_By>;
  Q1?: Maybe<Order_By>;
  Q2?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type QuestionPairs_Min_Fields = {
  __typename?: 'QuestionPairs_min_fields';
  Id?: Maybe<Scalars['Int']>;
  Q1?: Maybe<Scalars['String']>;
  Q2?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "QuestionPairs" */
export type QuestionPairs_Min_Order_By = {
  Id?: Maybe<Order_By>;
  Q1?: Maybe<Order_By>;
  Q2?: Maybe<Order_By>;
};

/** response of any mutation on the table "QuestionPairs" */
export type QuestionPairs_Mutation_Response = {
  __typename?: 'QuestionPairs_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<QuestionPairs>;
};

/** input type for inserting object relation for remote table "QuestionPairs" */
export type QuestionPairs_Obj_Rel_Insert_Input = {
  data: QuestionPairs_Insert_Input;
  on_conflict?: Maybe<QuestionPairs_On_Conflict>;
};

/** on conflict condition type for table "QuestionPairs" */
export type QuestionPairs_On_Conflict = {
  constraint: QuestionPairs_Constraint;
  update_columns: Array<QuestionPairs_Update_Column>;
  where?: Maybe<QuestionPairs_Bool_Exp>;
};

/** ordering options when selecting data from "QuestionPairs" */
export type QuestionPairs_Order_By = {
  Id?: Maybe<Order_By>;
  Q1?: Maybe<Order_By>;
  Q2?: Maybe<Order_By>;
  Similarity?: Maybe<Order_By>;
  Test?: Maybe<Order_By>;
};

/** primary key columns input for table: "QuestionPairs" */
export type QuestionPairs_Pk_Columns_Input = {
  Id: Scalars['Int'];
};

/** select columns of table "QuestionPairs" */
export enum QuestionPairs_Select_Column {
  /** column name */
  Id = 'Id',
  /** column name */
  Q1 = 'Q1',
  /** column name */
  Q2 = 'Q2',
  /** column name */
  Similarity = 'Similarity',
  /** column name */
  Test = 'Test'
}

/** input type for updating data in table "QuestionPairs" */
export type QuestionPairs_Set_Input = {
  Id?: Maybe<Scalars['Int']>;
  Q1?: Maybe<Scalars['String']>;
  Q2?: Maybe<Scalars['String']>;
  Similarity?: Maybe<Scalars['Boolean']>;
  Test?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type QuestionPairs_Stddev_Fields = {
  __typename?: 'QuestionPairs_stddev_fields';
  Id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "QuestionPairs" */
export type QuestionPairs_Stddev_Order_By = {
  Id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type QuestionPairs_Stddev_Pop_Fields = {
  __typename?: 'QuestionPairs_stddev_pop_fields';
  Id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "QuestionPairs" */
export type QuestionPairs_Stddev_Pop_Order_By = {
  Id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type QuestionPairs_Stddev_Samp_Fields = {
  __typename?: 'QuestionPairs_stddev_samp_fields';
  Id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "QuestionPairs" */
export type QuestionPairs_Stddev_Samp_Order_By = {
  Id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type QuestionPairs_Sum_Fields = {
  __typename?: 'QuestionPairs_sum_fields';
  Id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "QuestionPairs" */
export type QuestionPairs_Sum_Order_By = {
  Id?: Maybe<Order_By>;
};

/** update columns of table "QuestionPairs" */
export enum QuestionPairs_Update_Column {
  /** column name */
  Id = 'Id',
  /** column name */
  Q1 = 'Q1',
  /** column name */
  Q2 = 'Q2',
  /** column name */
  Similarity = 'Similarity',
  /** column name */
  Test = 'Test'
}

/** aggregate var_pop on columns */
export type QuestionPairs_Var_Pop_Fields = {
  __typename?: 'QuestionPairs_var_pop_fields';
  Id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "QuestionPairs" */
export type QuestionPairs_Var_Pop_Order_By = {
  Id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type QuestionPairs_Var_Samp_Fields = {
  __typename?: 'QuestionPairs_var_samp_fields';
  Id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "QuestionPairs" */
export type QuestionPairs_Var_Samp_Order_By = {
  Id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type QuestionPairs_Variance_Fields = {
  __typename?: 'QuestionPairs_variance_fields';
  Id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "QuestionPairs" */
export type QuestionPairs_Variance_Order_By = {
  Id?: Maybe<Order_By>;
};

/** columns and relationships of "Rasa_conversations" */
export type Rasa_Conversations = {
  __typename?: 'Rasa_conversations';
  Emotion_Detected?: Maybe<Scalars['String']>;
  Question?: Maybe<Scalars['String']>;
  Question_failed?: Maybe<Scalars['String']>;
  Question_id: Scalars['Int'];
};

/** aggregated selection of "Rasa_conversations" */
export type Rasa_Conversations_Aggregate = {
  __typename?: 'Rasa_conversations_aggregate';
  aggregate?: Maybe<Rasa_Conversations_Aggregate_Fields>;
  nodes: Array<Rasa_Conversations>;
};

/** aggregate fields of "Rasa_conversations" */
export type Rasa_Conversations_Aggregate_Fields = {
  __typename?: 'Rasa_conversations_aggregate_fields';
  avg?: Maybe<Rasa_Conversations_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Rasa_Conversations_Max_Fields>;
  min?: Maybe<Rasa_Conversations_Min_Fields>;
  stddev?: Maybe<Rasa_Conversations_Stddev_Fields>;
  stddev_pop?: Maybe<Rasa_Conversations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rasa_Conversations_Stddev_Samp_Fields>;
  sum?: Maybe<Rasa_Conversations_Sum_Fields>;
  var_pop?: Maybe<Rasa_Conversations_Var_Pop_Fields>;
  var_samp?: Maybe<Rasa_Conversations_Var_Samp_Fields>;
  variance?: Maybe<Rasa_Conversations_Variance_Fields>;
};


/** aggregate fields of "Rasa_conversations" */
export type Rasa_Conversations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rasa_Conversations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Rasa_conversations" */
export type Rasa_Conversations_Aggregate_Order_By = {
  avg?: Maybe<Rasa_Conversations_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rasa_Conversations_Max_Order_By>;
  min?: Maybe<Rasa_Conversations_Min_Order_By>;
  stddev?: Maybe<Rasa_Conversations_Stddev_Order_By>;
  stddev_pop?: Maybe<Rasa_Conversations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rasa_Conversations_Stddev_Samp_Order_By>;
  sum?: Maybe<Rasa_Conversations_Sum_Order_By>;
  var_pop?: Maybe<Rasa_Conversations_Var_Pop_Order_By>;
  var_samp?: Maybe<Rasa_Conversations_Var_Samp_Order_By>;
  variance?: Maybe<Rasa_Conversations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Rasa_conversations" */
export type Rasa_Conversations_Arr_Rel_Insert_Input = {
  data: Array<Rasa_Conversations_Insert_Input>;
  on_conflict?: Maybe<Rasa_Conversations_On_Conflict>;
};

/** aggregate avg on columns */
export type Rasa_Conversations_Avg_Fields = {
  __typename?: 'Rasa_conversations_avg_fields';
  Question_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Rasa_conversations" */
export type Rasa_Conversations_Avg_Order_By = {
  Question_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Rasa_conversations". All fields are combined with a logical 'AND'. */
export type Rasa_Conversations_Bool_Exp = {
  Emotion_Detected?: Maybe<String_Comparison_Exp>;
  Question?: Maybe<String_Comparison_Exp>;
  Question_failed?: Maybe<String_Comparison_Exp>;
  Question_id?: Maybe<Int_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rasa_Conversations_Bool_Exp>>>;
  _not?: Maybe<Rasa_Conversations_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rasa_Conversations_Bool_Exp>>>;
};

/** unique or primary key constraints on table "Rasa_conversations" */
export enum Rasa_Conversations_Constraint {
  /** unique or primary key constraint */
  RasaConversationsQuestionKey = 'Rasa_conversations_Question_key',
  /** unique or primary key constraint */
  RasaConversationsPkey = 'Rasa_conversations_pkey'
}

/** input type for incrementing integer column in table "Rasa_conversations" */
export type Rasa_Conversations_Inc_Input = {
  Question_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Rasa_conversations" */
export type Rasa_Conversations_Insert_Input = {
  Emotion_Detected?: Maybe<Scalars['String']>;
  Question?: Maybe<Scalars['String']>;
  Question_failed?: Maybe<Scalars['String']>;
  Question_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Rasa_Conversations_Max_Fields = {
  __typename?: 'Rasa_conversations_max_fields';
  Emotion_Detected?: Maybe<Scalars['String']>;
  Question?: Maybe<Scalars['String']>;
  Question_failed?: Maybe<Scalars['String']>;
  Question_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Rasa_conversations" */
export type Rasa_Conversations_Max_Order_By = {
  Emotion_Detected?: Maybe<Order_By>;
  Question?: Maybe<Order_By>;
  Question_failed?: Maybe<Order_By>;
  Question_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rasa_Conversations_Min_Fields = {
  __typename?: 'Rasa_conversations_min_fields';
  Emotion_Detected?: Maybe<Scalars['String']>;
  Question?: Maybe<Scalars['String']>;
  Question_failed?: Maybe<Scalars['String']>;
  Question_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Rasa_conversations" */
export type Rasa_Conversations_Min_Order_By = {
  Emotion_Detected?: Maybe<Order_By>;
  Question?: Maybe<Order_By>;
  Question_failed?: Maybe<Order_By>;
  Question_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "Rasa_conversations" */
export type Rasa_Conversations_Mutation_Response = {
  __typename?: 'Rasa_conversations_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Rasa_Conversations>;
};

/** input type for inserting object relation for remote table "Rasa_conversations" */
export type Rasa_Conversations_Obj_Rel_Insert_Input = {
  data: Rasa_Conversations_Insert_Input;
  on_conflict?: Maybe<Rasa_Conversations_On_Conflict>;
};

/** on conflict condition type for table "Rasa_conversations" */
export type Rasa_Conversations_On_Conflict = {
  constraint: Rasa_Conversations_Constraint;
  update_columns: Array<Rasa_Conversations_Update_Column>;
  where?: Maybe<Rasa_Conversations_Bool_Exp>;
};

/** ordering options when selecting data from "Rasa_conversations" */
export type Rasa_Conversations_Order_By = {
  Emotion_Detected?: Maybe<Order_By>;
  Question?: Maybe<Order_By>;
  Question_failed?: Maybe<Order_By>;
  Question_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "Rasa_conversations" */
export type Rasa_Conversations_Pk_Columns_Input = {
  Question_id: Scalars['Int'];
};

/** select columns of table "Rasa_conversations" */
export enum Rasa_Conversations_Select_Column {
  /** column name */
  EmotionDetected = 'Emotion_Detected',
  /** column name */
  Question = 'Question',
  /** column name */
  QuestionFailed = 'Question_failed',
  /** column name */
  QuestionId = 'Question_id'
}

/** input type for updating data in table "Rasa_conversations" */
export type Rasa_Conversations_Set_Input = {
  Emotion_Detected?: Maybe<Scalars['String']>;
  Question?: Maybe<Scalars['String']>;
  Question_failed?: Maybe<Scalars['String']>;
  Question_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Rasa_Conversations_Stddev_Fields = {
  __typename?: 'Rasa_conversations_stddev_fields';
  Question_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Rasa_conversations" */
export type Rasa_Conversations_Stddev_Order_By = {
  Question_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rasa_Conversations_Stddev_Pop_Fields = {
  __typename?: 'Rasa_conversations_stddev_pop_fields';
  Question_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Rasa_conversations" */
export type Rasa_Conversations_Stddev_Pop_Order_By = {
  Question_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rasa_Conversations_Stddev_Samp_Fields = {
  __typename?: 'Rasa_conversations_stddev_samp_fields';
  Question_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Rasa_conversations" */
export type Rasa_Conversations_Stddev_Samp_Order_By = {
  Question_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rasa_Conversations_Sum_Fields = {
  __typename?: 'Rasa_conversations_sum_fields';
  Question_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Rasa_conversations" */
export type Rasa_Conversations_Sum_Order_By = {
  Question_id?: Maybe<Order_By>;
};

/** update columns of table "Rasa_conversations" */
export enum Rasa_Conversations_Update_Column {
  /** column name */
  EmotionDetected = 'Emotion_Detected',
  /** column name */
  Question = 'Question',
  /** column name */
  QuestionFailed = 'Question_failed',
  /** column name */
  QuestionId = 'Question_id'
}

/** aggregate var_pop on columns */
export type Rasa_Conversations_Var_Pop_Fields = {
  __typename?: 'Rasa_conversations_var_pop_fields';
  Question_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Rasa_conversations" */
export type Rasa_Conversations_Var_Pop_Order_By = {
  Question_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rasa_Conversations_Var_Samp_Fields = {
  __typename?: 'Rasa_conversations_var_samp_fields';
  Question_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Rasa_conversations" */
export type Rasa_Conversations_Var_Samp_Order_By = {
  Question_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rasa_Conversations_Variance_Fields = {
  __typename?: 'Rasa_conversations_variance_fields';
  Question_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Rasa_conversations" */
export type Rasa_Conversations_Variance_Order_By = {
  Question_id?: Maybe<Order_By>;
};

/** columns and relationships of "Request" */
export type Request = {
  __typename?: 'Request';
  /** An array relationship */
  Request_Categories: Array<Request_Category>;
  /** An aggregated array relationship */
  Request_Categories_aggregate: Request_Category_Aggregate;
  created_at: Scalars['timestamptz'];
  details?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lat: Scalars['numeric'];
  lng: Scalars['numeric'];
  mobile_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "Request" */
export type RequestRequest_CategoriesArgs = {
  distinct_on?: Maybe<Array<Request_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Request_Category_Order_By>>;
  where?: Maybe<Request_Category_Bool_Exp>;
};


/** columns and relationships of "Request" */
export type RequestRequest_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Request_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Request_Category_Order_By>>;
  where?: Maybe<Request_Category_Bool_Exp>;
};

/** columns and relationships of "Request_Category" */
export type Request_Category = {
  __typename?: 'Request_Category';
  /** An object relationship */
  Category: Category;
  category_id: Scalars['Int'];
  request_id: Scalars['Int'];
};

/** aggregated selection of "Request_Category" */
export type Request_Category_Aggregate = {
  __typename?: 'Request_Category_aggregate';
  aggregate?: Maybe<Request_Category_Aggregate_Fields>;
  nodes: Array<Request_Category>;
};

/** aggregate fields of "Request_Category" */
export type Request_Category_Aggregate_Fields = {
  __typename?: 'Request_Category_aggregate_fields';
  avg?: Maybe<Request_Category_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Request_Category_Max_Fields>;
  min?: Maybe<Request_Category_Min_Fields>;
  stddev?: Maybe<Request_Category_Stddev_Fields>;
  stddev_pop?: Maybe<Request_Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Request_Category_Stddev_Samp_Fields>;
  sum?: Maybe<Request_Category_Sum_Fields>;
  var_pop?: Maybe<Request_Category_Var_Pop_Fields>;
  var_samp?: Maybe<Request_Category_Var_Samp_Fields>;
  variance?: Maybe<Request_Category_Variance_Fields>;
};


/** aggregate fields of "Request_Category" */
export type Request_Category_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Request_Category_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Request_Category" */
export type Request_Category_Aggregate_Order_By = {
  avg?: Maybe<Request_Category_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Request_Category_Max_Order_By>;
  min?: Maybe<Request_Category_Min_Order_By>;
  stddev?: Maybe<Request_Category_Stddev_Order_By>;
  stddev_pop?: Maybe<Request_Category_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Request_Category_Stddev_Samp_Order_By>;
  sum?: Maybe<Request_Category_Sum_Order_By>;
  var_pop?: Maybe<Request_Category_Var_Pop_Order_By>;
  var_samp?: Maybe<Request_Category_Var_Samp_Order_By>;
  variance?: Maybe<Request_Category_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Request_Category" */
export type Request_Category_Arr_Rel_Insert_Input = {
  data: Array<Request_Category_Insert_Input>;
  on_conflict?: Maybe<Request_Category_On_Conflict>;
};

/** aggregate avg on columns */
export type Request_Category_Avg_Fields = {
  __typename?: 'Request_Category_avg_fields';
  category_id?: Maybe<Scalars['Float']>;
  request_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Request_Category" */
export type Request_Category_Avg_Order_By = {
  category_id?: Maybe<Order_By>;
  request_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Request_Category". All fields are combined with a logical 'AND'. */
export type Request_Category_Bool_Exp = {
  Category?: Maybe<Category_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Request_Category_Bool_Exp>>>;
  _not?: Maybe<Request_Category_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Request_Category_Bool_Exp>>>;
  category_id?: Maybe<Int_Comparison_Exp>;
  request_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Request_Category" */
export enum Request_Category_Constraint {
  /** unique or primary key constraint */
  RequestCategoryPkey = 'Request_Category_pkey'
}

/** input type for incrementing integer column in table "Request_Category" */
export type Request_Category_Inc_Input = {
  category_id?: Maybe<Scalars['Int']>;
  request_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Request_Category" */
export type Request_Category_Insert_Input = {
  Category?: Maybe<Category_Obj_Rel_Insert_Input>;
  category_id?: Maybe<Scalars['Int']>;
  request_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Request_Category_Max_Fields = {
  __typename?: 'Request_Category_max_fields';
  category_id?: Maybe<Scalars['Int']>;
  request_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Request_Category" */
export type Request_Category_Max_Order_By = {
  category_id?: Maybe<Order_By>;
  request_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Request_Category_Min_Fields = {
  __typename?: 'Request_Category_min_fields';
  category_id?: Maybe<Scalars['Int']>;
  request_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Request_Category" */
export type Request_Category_Min_Order_By = {
  category_id?: Maybe<Order_By>;
  request_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "Request_Category" */
export type Request_Category_Mutation_Response = {
  __typename?: 'Request_Category_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Request_Category>;
};

/** input type for inserting object relation for remote table "Request_Category" */
export type Request_Category_Obj_Rel_Insert_Input = {
  data: Request_Category_Insert_Input;
  on_conflict?: Maybe<Request_Category_On_Conflict>;
};

/** on conflict condition type for table "Request_Category" */
export type Request_Category_On_Conflict = {
  constraint: Request_Category_Constraint;
  update_columns: Array<Request_Category_Update_Column>;
  where?: Maybe<Request_Category_Bool_Exp>;
};

/** ordering options when selecting data from "Request_Category" */
export type Request_Category_Order_By = {
  Category?: Maybe<Category_Order_By>;
  category_id?: Maybe<Order_By>;
  request_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "Request_Category" */
export type Request_Category_Pk_Columns_Input = {
  category_id: Scalars['Int'];
  request_id: Scalars['Int'];
};

/** select columns of table "Request_Category" */
export enum Request_Category_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  RequestId = 'request_id'
}

/** input type for updating data in table "Request_Category" */
export type Request_Category_Set_Input = {
  category_id?: Maybe<Scalars['Int']>;
  request_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Request_Category_Stddev_Fields = {
  __typename?: 'Request_Category_stddev_fields';
  category_id?: Maybe<Scalars['Float']>;
  request_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Request_Category" */
export type Request_Category_Stddev_Order_By = {
  category_id?: Maybe<Order_By>;
  request_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Request_Category_Stddev_Pop_Fields = {
  __typename?: 'Request_Category_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  request_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Request_Category" */
export type Request_Category_Stddev_Pop_Order_By = {
  category_id?: Maybe<Order_By>;
  request_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Request_Category_Stddev_Samp_Fields = {
  __typename?: 'Request_Category_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  request_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Request_Category" */
export type Request_Category_Stddev_Samp_Order_By = {
  category_id?: Maybe<Order_By>;
  request_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Request_Category_Sum_Fields = {
  __typename?: 'Request_Category_sum_fields';
  category_id?: Maybe<Scalars['Int']>;
  request_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Request_Category" */
export type Request_Category_Sum_Order_By = {
  category_id?: Maybe<Order_By>;
  request_id?: Maybe<Order_By>;
};

/** update columns of table "Request_Category" */
export enum Request_Category_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  RequestId = 'request_id'
}

/** aggregate var_pop on columns */
export type Request_Category_Var_Pop_Fields = {
  __typename?: 'Request_Category_var_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  request_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Request_Category" */
export type Request_Category_Var_Pop_Order_By = {
  category_id?: Maybe<Order_By>;
  request_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Request_Category_Var_Samp_Fields = {
  __typename?: 'Request_Category_var_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  request_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Request_Category" */
export type Request_Category_Var_Samp_Order_By = {
  category_id?: Maybe<Order_By>;
  request_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Request_Category_Variance_Fields = {
  __typename?: 'Request_Category_variance_fields';
  category_id?: Maybe<Scalars['Float']>;
  request_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Request_Category" */
export type Request_Category_Variance_Order_By = {
  category_id?: Maybe<Order_By>;
  request_id?: Maybe<Order_By>;
};

/** aggregated selection of "Request" */
export type Request_Aggregate = {
  __typename?: 'Request_aggregate';
  aggregate?: Maybe<Request_Aggregate_Fields>;
  nodes: Array<Request>;
};

/** aggregate fields of "Request" */
export type Request_Aggregate_Fields = {
  __typename?: 'Request_aggregate_fields';
  avg?: Maybe<Request_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Request_Max_Fields>;
  min?: Maybe<Request_Min_Fields>;
  stddev?: Maybe<Request_Stddev_Fields>;
  stddev_pop?: Maybe<Request_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Request_Stddev_Samp_Fields>;
  sum?: Maybe<Request_Sum_Fields>;
  var_pop?: Maybe<Request_Var_Pop_Fields>;
  var_samp?: Maybe<Request_Var_Samp_Fields>;
  variance?: Maybe<Request_Variance_Fields>;
};


/** aggregate fields of "Request" */
export type Request_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Request_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Request" */
export type Request_Aggregate_Order_By = {
  avg?: Maybe<Request_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Request_Max_Order_By>;
  min?: Maybe<Request_Min_Order_By>;
  stddev?: Maybe<Request_Stddev_Order_By>;
  stddev_pop?: Maybe<Request_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Request_Stddev_Samp_Order_By>;
  sum?: Maybe<Request_Sum_Order_By>;
  var_pop?: Maybe<Request_Var_Pop_Order_By>;
  var_samp?: Maybe<Request_Var_Samp_Order_By>;
  variance?: Maybe<Request_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Request" */
export type Request_Arr_Rel_Insert_Input = {
  data: Array<Request_Insert_Input>;
  on_conflict?: Maybe<Request_On_Conflict>;
};

/** aggregate avg on columns */
export type Request_Avg_Fields = {
  __typename?: 'Request_avg_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Request" */
export type Request_Avg_Order_By = {
  id?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Request". All fields are combined with a logical 'AND'. */
export type Request_Bool_Exp = {
  Request_Categories?: Maybe<Request_Category_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Request_Bool_Exp>>>;
  _not?: Maybe<Request_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Request_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  details?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  lat?: Maybe<Numeric_Comparison_Exp>;
  lng?: Maybe<Numeric_Comparison_Exp>;
  mobile_number?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Request" */
export enum Request_Constraint {
  /** unique or primary key constraint */
  RequestPkey = 'Request_pkey'
}

/** input type for incrementing integer column in table "Request" */
export type Request_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "Request" */
export type Request_Insert_Input = {
  Request_Categories?: Maybe<Request_Category_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  mobile_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Request_Max_Fields = {
  __typename?: 'Request_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  mobile_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Request" */
export type Request_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  details?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
  mobile_number?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Request_Min_Fields = {
  __typename?: 'Request_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  mobile_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Request" */
export type Request_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  details?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
  mobile_number?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Request" */
export type Request_Mutation_Response = {
  __typename?: 'Request_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Request>;
};

/** input type for inserting object relation for remote table "Request" */
export type Request_Obj_Rel_Insert_Input = {
  data: Request_Insert_Input;
  on_conflict?: Maybe<Request_On_Conflict>;
};

/** on conflict condition type for table "Request" */
export type Request_On_Conflict = {
  constraint: Request_Constraint;
  update_columns: Array<Request_Update_Column>;
  where?: Maybe<Request_Bool_Exp>;
};

/** ordering options when selecting data from "Request" */
export type Request_Order_By = {
  Request_Categories_aggregate?: Maybe<Request_Category_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  details?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
  mobile_number?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "Request" */
export type Request_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Request" */
export enum Request_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Details = 'details',
  /** column name */
  Id = 'id',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  MobileNumber = 'mobile_number',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Request" */
export type Request_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  mobile_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Request_Stddev_Fields = {
  __typename?: 'Request_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Request" */
export type Request_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Request_Stddev_Pop_Fields = {
  __typename?: 'Request_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Request" */
export type Request_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Request_Stddev_Samp_Fields = {
  __typename?: 'Request_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Request" */
export type Request_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Request_Sum_Fields = {
  __typename?: 'Request_sum_fields';
  id?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "Request" */
export type Request_Sum_Order_By = {
  id?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
};

/** update columns of table "Request" */
export enum Request_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Details = 'details',
  /** column name */
  Id = 'id',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  MobileNumber = 'mobile_number',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Request_Var_Pop_Fields = {
  __typename?: 'Request_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Request" */
export type Request_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Request_Var_Samp_Fields = {
  __typename?: 'Request_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Request" */
export type Request_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Request_Variance_Fields = {
  __typename?: 'Request_variance_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Request" */
export type Request_Variance_Order_By = {
  id?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

/** columns and relationships of "User" */
export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['Int'];
  password: Scalars['String'];
  user_name: Scalars['String'];
};

/** aggregated selection of "User" */
export type User_Aggregate = {
  __typename?: 'User_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "User" */
export type User_Aggregate_Fields = {
  __typename?: 'User_aggregate_fields';
  avg?: Maybe<User_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
  stddev?: Maybe<User_Stddev_Fields>;
  stddev_pop?: Maybe<User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Stddev_Samp_Fields>;
  sum?: Maybe<User_Sum_Fields>;
  var_pop?: Maybe<User_Var_Pop_Fields>;
  var_samp?: Maybe<User_Var_Samp_Fields>;
  variance?: Maybe<User_Variance_Fields>;
};


/** aggregate fields of "User" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "User" */
export type User_Aggregate_Order_By = {
  avg?: Maybe<User_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<User_Max_Order_By>;
  min?: Maybe<User_Min_Order_By>;
  stddev?: Maybe<User_Stddev_Order_By>;
  stddev_pop?: Maybe<User_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<User_Stddev_Samp_Order_By>;
  sum?: Maybe<User_Sum_Order_By>;
  var_pop?: Maybe<User_Var_Pop_Order_By>;
  var_samp?: Maybe<User_Var_Samp_Order_By>;
  variance?: Maybe<User_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "User" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Avg_Fields = {
  __typename?: 'User_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "User" */
export type User_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "User". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  _not?: Maybe<User_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  password?: Maybe<String_Comparison_Exp>;
  user_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "User" */
export enum User_Constraint {
  /** unique or primary key constraint */
  UserEmailKey = 'User_email_key',
  /** unique or primary key constraint */
  UserPkey = 'User_pkey',
  /** unique or primary key constraint */
  UserUserNameKey = 'User_user_name_key'
}

/** input type for incrementing integer column in table "User" */
export type User_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "User" */
export type User_Insert_Input = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  password?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'User_max_fields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  password?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "User" */
export type User_Max_Order_By = {
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  user_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'User_min_fields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  password?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "User" */
export type User_Min_Order_By = {
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  user_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "User" */
export type User_Mutation_Response = {
  __typename?: 'User_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "User" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** on conflict condition type for table "User" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns: Array<User_Update_Column>;
  where?: Maybe<User_Bool_Exp>;
};

/** ordering options when selecting data from "User" */
export type User_Order_By = {
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  user_name?: Maybe<Order_By>;
};

/** primary key columns input for table: "User" */
export type User_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "User" */
export enum User_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Password = 'password',
  /** column name */
  UserName = 'user_name'
}

/** input type for updating data in table "User" */
export type User_Set_Input = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  password?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type User_Stddev_Fields = {
  __typename?: 'User_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "User" */
export type User_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Stddev_Pop_Fields = {
  __typename?: 'User_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "User" */
export type User_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Stddev_Samp_Fields = {
  __typename?: 'User_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "User" */
export type User_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type User_Sum_Fields = {
  __typename?: 'User_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "User" */
export type User_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "User" */
export enum User_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Password = 'password',
  /** column name */
  UserName = 'user_name'
}

/** aggregate var_pop on columns */
export type User_Var_Pop_Fields = {
  __typename?: 'User_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "User" */
export type User_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Var_Samp_Fields = {
  __typename?: 'User_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "User" */
export type User_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Variance_Fields = {
  __typename?: 'User_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "User" */
export type User_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "Category" */
  delete_Category?: Maybe<Category_Mutation_Response>;
  /** delete single row from the table: "Category" */
  delete_Category_by_pk?: Maybe<Category>;
  /** delete data from the table: "QuestionPairs" */
  delete_QuestionPairs?: Maybe<QuestionPairs_Mutation_Response>;
  /** delete single row from the table: "QuestionPairs" */
  delete_QuestionPairs_by_pk?: Maybe<QuestionPairs>;
  /** delete data from the table: "Rasa_conversations" */
  delete_Rasa_conversations?: Maybe<Rasa_Conversations_Mutation_Response>;
  /** delete single row from the table: "Rasa_conversations" */
  delete_Rasa_conversations_by_pk?: Maybe<Rasa_Conversations>;
  /** delete data from the table: "Request" */
  delete_Request?: Maybe<Request_Mutation_Response>;
  /** delete data from the table: "Request_Category" */
  delete_Request_Category?: Maybe<Request_Category_Mutation_Response>;
  /** delete single row from the table: "Request_Category" */
  delete_Request_Category_by_pk?: Maybe<Request_Category>;
  /** delete single row from the table: "Request" */
  delete_Request_by_pk?: Maybe<Request>;
  /** delete data from the table: "User" */
  delete_User?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "User" */
  delete_User_by_pk?: Maybe<User>;
  /** insert data into the table: "Category" */
  insert_Category?: Maybe<Category_Mutation_Response>;
  /** insert a single row into the table: "Category" */
  insert_Category_one?: Maybe<Category>;
  /** insert data into the table: "QuestionPairs" */
  insert_QuestionPairs?: Maybe<QuestionPairs_Mutation_Response>;
  /** insert a single row into the table: "QuestionPairs" */
  insert_QuestionPairs_one?: Maybe<QuestionPairs>;
  /** insert data into the table: "Rasa_conversations" */
  insert_Rasa_conversations?: Maybe<Rasa_Conversations_Mutation_Response>;
  /** insert a single row into the table: "Rasa_conversations" */
  insert_Rasa_conversations_one?: Maybe<Rasa_Conversations>;
  /** insert data into the table: "Request" */
  insert_Request?: Maybe<Request_Mutation_Response>;
  /** insert data into the table: "Request_Category" */
  insert_Request_Category?: Maybe<Request_Category_Mutation_Response>;
  /** insert a single row into the table: "Request_Category" */
  insert_Request_Category_one?: Maybe<Request_Category>;
  /** insert a single row into the table: "Request" */
  insert_Request_one?: Maybe<Request>;
  /** insert data into the table: "User" */
  insert_User?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "User" */
  insert_User_one?: Maybe<User>;
  /** update data of the table: "Category" */
  update_Category?: Maybe<Category_Mutation_Response>;
  /** update single row of the table: "Category" */
  update_Category_by_pk?: Maybe<Category>;
  /** update data of the table: "QuestionPairs" */
  update_QuestionPairs?: Maybe<QuestionPairs_Mutation_Response>;
  /** update single row of the table: "QuestionPairs" */
  update_QuestionPairs_by_pk?: Maybe<QuestionPairs>;
  /** update data of the table: "Rasa_conversations" */
  update_Rasa_conversations?: Maybe<Rasa_Conversations_Mutation_Response>;
  /** update single row of the table: "Rasa_conversations" */
  update_Rasa_conversations_by_pk?: Maybe<Rasa_Conversations>;
  /** update data of the table: "Request" */
  update_Request?: Maybe<Request_Mutation_Response>;
  /** update data of the table: "Request_Category" */
  update_Request_Category?: Maybe<Request_Category_Mutation_Response>;
  /** update single row of the table: "Request_Category" */
  update_Request_Category_by_pk?: Maybe<Request_Category>;
  /** update single row of the table: "Request" */
  update_Request_by_pk?: Maybe<Request>;
  /** update data of the table: "User" */
  update_User?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "User" */
  update_User_by_pk?: Maybe<User>;
};


/** mutation root */
export type Mutation_RootDelete_CategoryArgs = {
  where: Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Category_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_QuestionPairsArgs = {
  where: QuestionPairs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_QuestionPairs_By_PkArgs = {
  Id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Rasa_ConversationsArgs = {
  where: Rasa_Conversations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rasa_Conversations_By_PkArgs = {
  Question_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_RequestArgs = {
  where: Request_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Request_CategoryArgs = {
  where: Request_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Request_Category_By_PkArgs = {
  category_id: Scalars['Int'];
  request_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Request_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert_CategoryArgs = {
  objects: Array<Category_Insert_Input>;
  on_conflict?: Maybe<Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Category_OneArgs = {
  object: Category_Insert_Input;
  on_conflict?: Maybe<Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionPairsArgs = {
  objects: Array<QuestionPairs_Insert_Input>;
  on_conflict?: Maybe<QuestionPairs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionPairs_OneArgs = {
  object: QuestionPairs_Insert_Input;
  on_conflict?: Maybe<QuestionPairs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rasa_ConversationsArgs = {
  objects: Array<Rasa_Conversations_Insert_Input>;
  on_conflict?: Maybe<Rasa_Conversations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rasa_Conversations_OneArgs = {
  object: Rasa_Conversations_Insert_Input;
  on_conflict?: Maybe<Rasa_Conversations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RequestArgs = {
  objects: Array<Request_Insert_Input>;
  on_conflict?: Maybe<Request_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Request_CategoryArgs = {
  objects: Array<Request_Category_Insert_Input>;
  on_conflict?: Maybe<Request_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Request_Category_OneArgs = {
  object: Request_Category_Insert_Input;
  on_conflict?: Maybe<Request_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Request_OneArgs = {
  object: Request_Insert_Input;
  on_conflict?: Maybe<Request_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_CategoryArgs = {
  _inc?: Maybe<Category_Inc_Input>;
  _set?: Maybe<Category_Set_Input>;
  where: Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Category_By_PkArgs = {
  _inc?: Maybe<Category_Inc_Input>;
  _set?: Maybe<Category_Set_Input>;
  pk_columns: Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionPairsArgs = {
  _inc?: Maybe<QuestionPairs_Inc_Input>;
  _set?: Maybe<QuestionPairs_Set_Input>;
  where: QuestionPairs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionPairs_By_PkArgs = {
  _inc?: Maybe<QuestionPairs_Inc_Input>;
  _set?: Maybe<QuestionPairs_Set_Input>;
  pk_columns: QuestionPairs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rasa_ConversationsArgs = {
  _inc?: Maybe<Rasa_Conversations_Inc_Input>;
  _set?: Maybe<Rasa_Conversations_Set_Input>;
  where: Rasa_Conversations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rasa_Conversations_By_PkArgs = {
  _inc?: Maybe<Rasa_Conversations_Inc_Input>;
  _set?: Maybe<Rasa_Conversations_Set_Input>;
  pk_columns: Rasa_Conversations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RequestArgs = {
  _inc?: Maybe<Request_Inc_Input>;
  _set?: Maybe<Request_Set_Input>;
  where: Request_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Request_CategoryArgs = {
  _inc?: Maybe<Request_Category_Inc_Input>;
  _set?: Maybe<Request_Category_Set_Input>;
  where: Request_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Request_Category_By_PkArgs = {
  _inc?: Maybe<Request_Category_Inc_Input>;
  _set?: Maybe<Request_Category_Set_Input>;
  pk_columns: Request_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Request_By_PkArgs = {
  _inc?: Maybe<Request_Inc_Input>;
  _set?: Maybe<Request_Set_Input>;
  pk_columns: Request_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _inc?: Maybe<User_Inc_Input>;
  _set?: Maybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _inc?: Maybe<User_Inc_Input>;
  _set?: Maybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** expression to compare columns of type numeric. All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "Category" */
  Category: Array<Category>;
  /** fetch aggregated fields from the table: "Category" */
  Category_aggregate: Category_Aggregate;
  /** fetch data from the table: "Category" using primary key columns */
  Category_by_pk?: Maybe<Category>;
  /** fetch data from the table: "QuestionPairs" */
  QuestionPairs: Array<QuestionPairs>;
  /** fetch aggregated fields from the table: "QuestionPairs" */
  QuestionPairs_aggregate: QuestionPairs_Aggregate;
  /** fetch data from the table: "QuestionPairs" using primary key columns */
  QuestionPairs_by_pk?: Maybe<QuestionPairs>;
  /** fetch data from the table: "Rasa_conversations" */
  Rasa_conversations: Array<Rasa_Conversations>;
  /** fetch aggregated fields from the table: "Rasa_conversations" */
  Rasa_conversations_aggregate: Rasa_Conversations_Aggregate;
  /** fetch data from the table: "Rasa_conversations" using primary key columns */
  Rasa_conversations_by_pk?: Maybe<Rasa_Conversations>;
  /** fetch data from the table: "Request" */
  Request: Array<Request>;
  /** fetch data from the table: "Request_Category" */
  Request_Category: Array<Request_Category>;
  /** fetch aggregated fields from the table: "Request_Category" */
  Request_Category_aggregate: Request_Category_Aggregate;
  /** fetch data from the table: "Request_Category" using primary key columns */
  Request_Category_by_pk?: Maybe<Request_Category>;
  /** fetch aggregated fields from the table: "Request" */
  Request_aggregate: Request_Aggregate;
  /** fetch data from the table: "Request" using primary key columns */
  Request_by_pk?: Maybe<Request>;
  /** fetch data from the table: "User" */
  User: Array<User>;
  /** fetch aggregated fields from the table: "User" */
  User_aggregate: User_Aggregate;
  /** fetch data from the table: "User" using primary key columns */
  User_by_pk?: Maybe<User>;
};


/** query root */
export type Query_RootCategoryArgs = {
  distinct_on?: Maybe<Array<Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Order_By>>;
  where?: Maybe<Category_Bool_Exp>;
};


/** query root */
export type Query_RootCategory_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Order_By>>;
  where?: Maybe<Category_Bool_Exp>;
};


/** query root */
export type Query_RootCategory_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootQuestionPairsArgs = {
  distinct_on?: Maybe<Array<QuestionPairs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QuestionPairs_Order_By>>;
  where?: Maybe<QuestionPairs_Bool_Exp>;
};


/** query root */
export type Query_RootQuestionPairs_AggregateArgs = {
  distinct_on?: Maybe<Array<QuestionPairs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QuestionPairs_Order_By>>;
  where?: Maybe<QuestionPairs_Bool_Exp>;
};


/** query root */
export type Query_RootQuestionPairs_By_PkArgs = {
  Id: Scalars['Int'];
};


/** query root */
export type Query_RootRasa_ConversationsArgs = {
  distinct_on?: Maybe<Array<Rasa_Conversations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rasa_Conversations_Order_By>>;
  where?: Maybe<Rasa_Conversations_Bool_Exp>;
};


/** query root */
export type Query_RootRasa_Conversations_AggregateArgs = {
  distinct_on?: Maybe<Array<Rasa_Conversations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rasa_Conversations_Order_By>>;
  where?: Maybe<Rasa_Conversations_Bool_Exp>;
};


/** query root */
export type Query_RootRasa_Conversations_By_PkArgs = {
  Question_id: Scalars['Int'];
};


/** query root */
export type Query_RootRequestArgs = {
  distinct_on?: Maybe<Array<Request_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Request_Order_By>>;
  where?: Maybe<Request_Bool_Exp>;
};


/** query root */
export type Query_RootRequest_CategoryArgs = {
  distinct_on?: Maybe<Array<Request_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Request_Category_Order_By>>;
  where?: Maybe<Request_Category_Bool_Exp>;
};


/** query root */
export type Query_RootRequest_Category_AggregateArgs = {
  distinct_on?: Maybe<Array<Request_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Request_Category_Order_By>>;
  where?: Maybe<Request_Category_Bool_Exp>;
};


/** query root */
export type Query_RootRequest_Category_By_PkArgs = {
  category_id: Scalars['Int'];
  request_id: Scalars['Int'];
};


/** query root */
export type Query_RootRequest_AggregateArgs = {
  distinct_on?: Maybe<Array<Request_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Request_Order_By>>;
  where?: Maybe<Request_Bool_Exp>;
};


/** query root */
export type Query_RootRequest_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** query root */
export type Query_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** query root */
export type Query_RootUser_By_PkArgs = {
  id: Scalars['Int'];
};

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "Category" */
  Category: Array<Category>;
  /** fetch aggregated fields from the table: "Category" */
  Category_aggregate: Category_Aggregate;
  /** fetch data from the table: "Category" using primary key columns */
  Category_by_pk?: Maybe<Category>;
  /** fetch data from the table: "QuestionPairs" */
  QuestionPairs: Array<QuestionPairs>;
  /** fetch aggregated fields from the table: "QuestionPairs" */
  QuestionPairs_aggregate: QuestionPairs_Aggregate;
  /** fetch data from the table: "QuestionPairs" using primary key columns */
  QuestionPairs_by_pk?: Maybe<QuestionPairs>;
  /** fetch data from the table: "Rasa_conversations" */
  Rasa_conversations: Array<Rasa_Conversations>;
  /** fetch aggregated fields from the table: "Rasa_conversations" */
  Rasa_conversations_aggregate: Rasa_Conversations_Aggregate;
  /** fetch data from the table: "Rasa_conversations" using primary key columns */
  Rasa_conversations_by_pk?: Maybe<Rasa_Conversations>;
  /** fetch data from the table: "Request" */
  Request: Array<Request>;
  /** fetch data from the table: "Request_Category" */
  Request_Category: Array<Request_Category>;
  /** fetch aggregated fields from the table: "Request_Category" */
  Request_Category_aggregate: Request_Category_Aggregate;
  /** fetch data from the table: "Request_Category" using primary key columns */
  Request_Category_by_pk?: Maybe<Request_Category>;
  /** fetch aggregated fields from the table: "Request" */
  Request_aggregate: Request_Aggregate;
  /** fetch data from the table: "Request" using primary key columns */
  Request_by_pk?: Maybe<Request>;
  /** fetch data from the table: "User" */
  User: Array<User>;
  /** fetch aggregated fields from the table: "User" */
  User_aggregate: User_Aggregate;
  /** fetch data from the table: "User" using primary key columns */
  User_by_pk?: Maybe<User>;
};


/** subscription root */
export type Subscription_RootCategoryArgs = {
  distinct_on?: Maybe<Array<Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Order_By>>;
  where?: Maybe<Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCategory_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Order_By>>;
  where?: Maybe<Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCategory_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootQuestionPairsArgs = {
  distinct_on?: Maybe<Array<QuestionPairs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QuestionPairs_Order_By>>;
  where?: Maybe<QuestionPairs_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootQuestionPairs_AggregateArgs = {
  distinct_on?: Maybe<Array<QuestionPairs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QuestionPairs_Order_By>>;
  where?: Maybe<QuestionPairs_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootQuestionPairs_By_PkArgs = {
  Id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootRasa_ConversationsArgs = {
  distinct_on?: Maybe<Array<Rasa_Conversations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rasa_Conversations_Order_By>>;
  where?: Maybe<Rasa_Conversations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRasa_Conversations_AggregateArgs = {
  distinct_on?: Maybe<Array<Rasa_Conversations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rasa_Conversations_Order_By>>;
  where?: Maybe<Rasa_Conversations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRasa_Conversations_By_PkArgs = {
  Question_id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootRequestArgs = {
  distinct_on?: Maybe<Array<Request_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Request_Order_By>>;
  where?: Maybe<Request_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRequest_CategoryArgs = {
  distinct_on?: Maybe<Array<Request_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Request_Category_Order_By>>;
  where?: Maybe<Request_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRequest_Category_AggregateArgs = {
  distinct_on?: Maybe<Array<Request_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Request_Category_Order_By>>;
  where?: Maybe<Request_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRequest_Category_By_PkArgs = {
  category_id: Scalars['Int'];
  request_id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootRequest_AggregateArgs = {
  distinct_on?: Maybe<Array<Request_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Request_Order_By>>;
  where?: Maybe<Request_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRequest_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['Int'];
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

export type QuestionPairsQueryQueryVariables = Exact<{
  isNull?: Maybe<Scalars['Boolean']>;
  isTested?: Maybe<Scalars['Boolean']>;
}>;


export type QuestionPairsQueryQuery = (
  { __typename?: 'query_root' }
  & { QuestionPairs: Array<(
    { __typename?: 'QuestionPairs' }
    & Pick<QuestionPairs, 'Id' | 'Q1' | 'Q2' | 'Similarity' | 'Test'>
  )> }
);

export type UpdateQuestionPairsMutationVariables = Exact<{
  id: Scalars['Int'];
  question_1: Scalars['String'];
  question_2: Scalars['String'];
  similar?: Maybe<Scalars['Boolean']>;
  test?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateQuestionPairsMutation = (
  { __typename?: 'mutation_root' }
  & { update_QuestionPairs?: Maybe<(
    { __typename?: 'QuestionPairs_mutation_response' }
    & Pick<QuestionPairs_Mutation_Response, 'affected_rows'>
  )> }
);

export type CategoryQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoryQueryQuery = (
  { __typename?: 'query_root' }
  & { Category: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )> }
);

export type InsertRequestMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  lat: Scalars['numeric'];
  lng: Scalars['numeric'];
  mobile_number?: Maybe<Scalars['String']>;
  category_id: Scalars['Int'];
  category_name: Scalars['String'];
}>;


export type InsertRequestMutation = (
  { __typename?: 'mutation_root' }
  & { insert_Request?: Maybe<(
    { __typename?: 'Request_mutation_response' }
    & { returning: Array<(
      { __typename?: 'Request' }
      & Pick<Request, 'id'>
    )> }
  )> }
);


export const QuestionPairsQueryDocument = `
    query QuestionPairsQuery($isNull: Boolean, $isTested: Boolean) {
  QuestionPairs(
    where: {_or: [{Test: {_is_null: $isNull}}, {Test: {_eq: $isTested}}]}
  ) {
    Id
    Q1
    Q2
    Similarity
    Test
  }
}
    `;
export const useQuestionPairsQueryQuery = <
      TData = QuestionPairsQueryQuery,
      TError = unknown
    >(
      variables?: QuestionPairsQueryQueryVariables, 
      options?: UseQueryOptions<QuestionPairsQueryQuery, TError, TData>
    ) => 
    useQuery<QuestionPairsQueryQuery, TError, TData>(
      ['QuestionPairsQuery', variables],
      fetchData<QuestionPairsQueryQuery, QuestionPairsQueryQueryVariables>(QuestionPairsQueryDocument, variables),
      options
    );
export const UpdateQuestionPairsDocument = `
    mutation updateQuestionPairs($id: Int!, $question_1: String!, $question_2: String!, $similar: Boolean, $test: Boolean) {
  update_QuestionPairs(
    where: {Id: {_eq: $id}}
    _set: {Q1: $question_1, Q2: $question_2, Similarity: $similar, Test: $test}
  ) {
    affected_rows
  }
}
    `;
export const useUpdateQuestionPairsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateQuestionPairsMutation, TError, UpdateQuestionPairsMutationVariables, TContext>) => 
    useMutation<UpdateQuestionPairsMutation, TError, UpdateQuestionPairsMutationVariables, TContext>(
      (variables?: UpdateQuestionPairsMutationVariables) => fetchData<UpdateQuestionPairsMutation, UpdateQuestionPairsMutationVariables>(UpdateQuestionPairsDocument, variables)(),
      options
    );
export const CategoryQueryDocument = `
    query CategoryQuery {
  Category {
    id
    name
  }
}
    `;
export const useCategoryQueryQuery = <
      TData = CategoryQueryQuery,
      TError = unknown
    >(
      variables?: CategoryQueryQueryVariables, 
      options?: UseQueryOptions<CategoryQueryQuery, TError, TData>
    ) => 
    useQuery<CategoryQueryQuery, TError, TData>(
      ['CategoryQuery', variables],
      fetchData<CategoryQueryQuery, CategoryQueryQueryVariables>(CategoryQueryDocument, variables),
      options
    );
export const InsertRequestDocument = `
    mutation insertRequest($name: String, $details: String, $lat: numeric!, $lng: numeric!, $mobile_number: String, $category_id: Int!, $category_name: String!) {
  insert_Request(
    objects: {name: $name, details: $details, lat: $lat, lng: $lng, mobile_number: $mobile_number, Request_Categories: {data: {Category: {data: {id: $category_id, name: $category_name}, on_conflict: {constraint: Category_id_key, update_columns: id}}}}}
  ) {
    returning {
      id
    }
  }
}
    `;
export const useInsertRequestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<InsertRequestMutation, TError, InsertRequestMutationVariables, TContext>) => 
    useMutation<InsertRequestMutation, TError, InsertRequestMutationVariables, TContext>(
      (variables?: InsertRequestMutationVariables) => fetchData<InsertRequestMutation, InsertRequestMutationVariables>(InsertRequestDocument, variables)(),
      options
    );