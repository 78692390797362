import { Controller, useFormContext } from 'react-hook-form';

import { IRadioOptionType } from '@/common/constants';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

interface Props {
  name: string;
  options: IRadioOptionType[];
  disabled?: boolean;
}

export function RadioInput(props: Props) {
  const classes = useStyles();
  const { control, setValue } = useFormContext();

  const { name, options } = props;

  const handleChange = (event: any) => {
    setValue(name, +event.target.value);
  };

  const renderRadioGroup = (field: any) => {
    return (
      <FormControl component="fieldset" className={classes.container}>
        <RadioGroup aria-label={name} {...field} onChange={handleChange}>
          {options.map((option: IRadioOptionType) => {
            return (
              <div key={option.id}>
                <FormControlLabel
                  value={option.id}
                  control={<Radio />}
                  label={option.label}
                />
                {option.details && (
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{ marginLeft: 30, marginBottom: 20 }}
                  >
                    {option.details}
                  </Typography>
                )}
              </div>
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  };
  return (
    <Controller
      render={({ field }) => renderRadioGroup(field)}
      control={control}
      name={name}
    />
  );
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    minWidth: 120
  }
}));
