import * as React from 'react';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { useQuestionPairsQueryQuery } from '../controller/schema';
import { TableRowView } from '../src/TableRowView';

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filter, setFilter] = React.useState(0);
  const [variables, setVariables] = React.useState({
    isNull: true,
    isTested: false
  });

  const { data } = useQuestionPairsQueryQuery(variables);

  React.useEffect(() => {
    let variables = { isNull: true, isTested: false };
    if (filter === 1) {
      variables.isTested = true;
      variables.isNull = false;
    } else if (filter === 2) {
      variables.isTested = true;
      variables.isNull = true;
    }
    setVariables(variables);
  }, [filter]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilter(event.target.value as number);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const displayPagination = () => {
    if (data && data.QuestionPairs && data.QuestionPairs.length > 0) {
      return (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.QuestionPairs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      );
    }
  };

  const tableHead = () => (
    <TableHead>
      <TableRow>
        <TableCell>Question 1</TableCell>
        <TableCell>Question 2</TableCell>
        <TableCell>Similar</TableCell>
        <TableCell>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Is Tested?</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filter}
              label="Is Tested?"
              onChange={handleChange}
            >
              <MenuItem value={0}>No</MenuItem>
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={2}>All</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
      </TableRow>
    </TableHead>
  );
  const displayRows = () => {
    // Trying to run Prod build
    if (data && data.QuestionPairs && data.QuestionPairs.length > 0) {
      const rows = data.QuestionPairs.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
      return rows.map((row) => <TableRowView row={row} key={row.Id} />);
    }
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          {tableHead()}
          <TableBody>{displayRows()}</TableBody>
        </Table>
      </TableContainer>
      {displayPagination()}
    </Paper>
  );
  // if (data && data.QuestionPairs && data.QuestionPairs.length > 0) {
  //   return displayRows(data.QuestionPairs);
  // }
  // return <div />;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflow: 'hidden',
    padding: '50px 20px'
  },
  container: {
    minHeight: 600
  }
});
