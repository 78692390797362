import { DetailedHTMLProps, TextareaHTMLAttributes } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

import { IRules } from '@/common/yup/common';

interface Props {
  name: string;
  businessRules?: IRules[];
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
}
export const TextAreaInput = (
  props: Props &
    DetailedHTMLProps<
      TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >
) => {
  const { control } = useFormContext();

  const { name, rules, ...otherProps } = props;
  return (
    <Controller
      render={({ field }) => (
        <textarea {...otherProps} {...field} style={{ width: '100%' }} />
      )}
      control={control}
      name={name}
      rules={rules}
    />
  );
};
