import { forwardRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { QuestionPairs, useUpdateQuestionPairsMutation } from '@/controller/schema';
import MuiAlert from '@material-ui/core/Alert';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { RadioInput } from './modules/shared/input/RadioInput';
import { TextAreaInput } from './modules/shared/input/TextAreaInput';

const Alert = forwardRef(function Alert(props: any, ref: any) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
interface Props {
  row: QuestionPairs;
}
export const TableRowView = ({ row }: Props) => {
  const { mutateAsync } = useUpdateQuestionPairsMutation();
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('success');

  const methods = useForm({
    defaultValues: {
      Q1: row.Q1,
      Q2: row.Q2,
      Similarity: row.Similarity
    }
  });

  const handleClose = (_: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const { handleSubmit } = methods;
  const onSubmit = (data: any) => {
    let similar = null;
    if (data.Similarity === 0) {
      similar = false;
    } else if (data.Similarity === 1) {
      similar = true;
    }
    try {
      if (similar !== null) {
        mutateAsync({
          id: row.Id,
          question_1: data.Q1,
          question_2: data.Q2,
          similar: similar as any,
          test: true
        }).then((data) => {
          if (data.update_QuestionPairs?.affected_rows === 1) {
            setSeverity('success');
            setOpen(true);
          } else {
            setSeverity('error');
            setOpen(true);
          }
        });
      } else {
        setOpen(true);
        setSeverity('info');
      }
    } catch (e) {
      setOpen(true);
      setSeverity('error');
    }
  };

  const displayMessage = () => {
    if (severity === 'success') {
      return 'Record was updated successfully';
    } else if (severity === 'error') {
      return 'Error occured while updating the record. Please contact administrator.';
    } else if (severity === 'info') {
      return 'Nothing Changed. Please select if the question is similar or not.';
    }
    return 'Something went wrong. Please contact administrator.';
  };
  return (
    <>
      <FormProvider {...methods}>
        <TableRow hover role="checkbox" tabIndex={-1} key={row.Id}>
          <TableCell>
            <TextAreaInput name="Q1" autoFocus={true} />
          </TableCell>
          <TableCell>
            <TextAreaInput name="Q2" />
          </TableCell>
          <TableCell>
            <RadioInput
              name="Similarity"
              options={[
                { id: 0, label: 'no' },
                { id: 1, label: 'yes' }
              ]}
            />
          </TableCell>
          <TableCell>
            <Button variant="contained" onClick={handleSubmit(onSubmit)}>
              Submit
            </Button>
          </TableCell>
        </TableRow>
      </FormProvider>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={severity}
          style={{ width: '100%' }}
        >
          {displayMessage()}
        </Alert>
      </Snackbar>
    </>
  );
};
